import * as React from 'react';
import { FC } from 'react';
import { ReferenceField, ReferenceFieldProps } from 'react-admin';

import FullNameField from './FullNameField';

const DoctorReferenceField: FC<
    Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> & {
        source?: string;
    }
> = props => (
    <ReferenceField source="doctor_id" reference="customers" {...props}>
        <FullNameField />
    </ReferenceField>
);

DoctorReferenceField.defaultProps = {
    source: 'doctor_id',
    addLabel: true,
};

export default DoctorReferenceField;
