var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { TOGGLE_LIST_ITEM_EXPAND, } from '../../../../actions/listActions';
var initialState = [];
var expanded = function (previousState, action) {
    if (previousState === void 0) { previousState = initialState; }
    if (action.type === TOGGLE_LIST_ITEM_EXPAND) {
        var index = previousState
            .map(function (el) { return el == action.payload; }) // eslint-disable-line eqeqeq
            .indexOf(true);
        if (index === -1) {
            // expand
            return __spreadArray(__spreadArray([], previousState), [action.payload]);
        }
        else {
            // close
            return __spreadArray(__spreadArray([], previousState.slice(0, index)), previousState.slice(index + 1));
        }
    }
    return previousState;
};
export default expanded;
