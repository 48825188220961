// in src/comments.js
import * as React from 'react';
import { useState } from 'react';
import { FC } from 'react';
import { Card, CardHeader, CardContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    DateField,
    EditButton,
    NumberField,
    TextField,
    BooleanField,
    useTranslate,
    RecordMap,
    Identifier,
    Record,
    FunctionField,
} from 'react-admin';

import CustomerReferenceField from '../visitors/CustomerReferenceField';
import DoctorReferenceField from '../visitors/DoctorReferenceField';

const useListStyles = makeStyles(theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: theme.typography.body1,
    cardContentRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0.5rem 0',
    },
}));

interface MobileGridProps {
    ids?: Identifier[];
    data?: RecordMap<Record>;
    basePath?: string;
}

const MedexMobileGrid: FC<MobileGridProps> = ({ ids, data, basePath }) => {
    const translate = useTranslate();
    const classes = useListStyles();
    const [loading, setLoading] = useState(false);

    // @ts-ignore
    const handleDownload = (id, test_id, phone) => {
        setLoading(true);
        const url =
            process.env.REACT_APP_API_SERVER! + '/commands/download-file';
        // @ts-ignore
        const auth = localStorage.getItem('auth');
        const options = {
            method: 'POST',
            mode: 'cors',
            // @ts-ignore
            body: JSON.stringify({
                id: id,
                test_id: test_id,
            }),
            headers: {
                'Content-Type': 'application/json',
                // @ts-ignore
                Authorization: 'Bearer ' + JSON.parse(auth).access_token,
            },
        };
        // @ts-ignore
        fetch(url, options)
            .then(res => res.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = phone + '-' + test_id + '.zip';
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove(); //afterwards we remove the element again
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                //Notify here
            });
    };

    if (!ids || !data || !basePath) {
        return null;
    }

    return (
        <div style={{ margin: '1em' }}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <span>
                                    {translate('resources.commands.name', 1)}
                                    :&nbsp;
                                    <TextField
                                        record={data[id]}
                                        source="reference"
                                    />
                                </span>
                            </div>
                        }
                    />
                    <CardContent className={classes.cardContent}>
                        <span className={classes.cardContentRow}>
                            Service Name:&nbsp;
                            <TextField
                                source="service_name"
                                label="Service Type"
                                record={data[id]}
                            />
                        </span>
                        <span className={classes.cardContentRow}>
                            Date:&nbsp;
                            <DateField
                                source="date"
                                showTime
                                record={data[id]}
                            />
                        </span>
                        <span className={classes.cardContentRow}>
                            Notes:&nbsp;
                            <TextField source="notes" record={data[id]} />
                        </span>
                        <span className={classes.cardContentRow}>
                            Status:&nbsp;
                            <TextField source="status" record={data[id]} />
                        </span>
                        {/* <span className={classes.cardContentRow}>
                            Partner Name:&nbsp;
                            <TextField source="doctor_name" record={data[id]} />
                        </span> */}
                        <span className={classes.cardContentRow}>
                            <FunctionField
                                record={data[id]}
                                // @ts-ignore
                                render={record =>
                                    record.status == 'delivered' && (
                                        <Button
                                            name="download"
                                            type="submit"
                                            // disabled={downloading}
                                            onClick={() =>
                                                handleDownload(
                                                    record.customer_id,
                                                    record.id,
                                                    record.phone
                                                )
                                            }
                                        >
                                            {loading ? 'Loading' : 'Download'}
                                        </Button>
                                    )
                                }
                            />
                        </span>
                        {/* <span className={classes.cardContentRow}>
                            {translate(
                                'resources.commands.fields.basket.total'
                            )}
                            :&nbsp;
                            <NumberField
                                record={data[id]}
                                source="total"
                                options={{ style: 'currency', currency: 'USD' }}
                            />
                        </span> */}
                        {/* <span className={classes.cardContentRow}>
                            {translate('resources.commands.fields.status')}
                            :&nbsp;
                            <TextField source="status" record={data[id]} />
                        </span> */}
                        {/* <span className={classes.cardContentRow}>
                            {translate('resources.commands.fields.returned')}
                            :&nbsp;
                            <BooleanField record={data[id]} source="returned" />
                        </span> */}
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

MedexMobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MedexMobileGrid;
