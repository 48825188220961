import * as React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    NavLink,
    useLocation,
} from 'react-router-dom';

import './style.css';
import logo from './images/logo.svg';

import { useEffect } from 'react';
import {
    Admin,
    Resource,
    DataProvider,
    fetchUtils,
    // useAuthProvider,
} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import restProvider from 'ra-data-simple-rest';
import Contact from './layout/Contact';
import Services from './layout/Services';
// import LogoutButton from './layout/LogoutButton';

import authProvider from './authProvider';
import otpAuthProvider from './otpAuthProvider';
import themeReducer from './themeReducer';
import { Login, LoginOTP, Layout, CustomLayout } from './layout';
// import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';

import visitors from './visitors';
import orders from './orders';
import lisOrders from './orders/listOnly';
import products from './products';
// import invoices from './invoices';
// import categories from './categories';
// import reviews from './reviews';
import Particles from 'react-particles-js';

window.onload = function () {
    const el = document.querySelectorAll('.menu__box li');
    // @ts-ignore
    const mobiletoggle = () =>
        // @ts-ignore
        document.getElementsByClassName('menu__btn')[0].click();
    // @ts-ignore
    el.forEach(function (item, index) {
        item.addEventListener('click', mobiletoggle, false);
    });
};
const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
}, 'en');

// @ts-ignore
const httpClient = (url, options = {}) => {
    // @ts-ignore
    if (!options.headers) {
        // @ts-ignore
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // @ts-ignore
    const auth = localStorage.getItem('auth');
    // @ts-ignore
    if (auth) {
        // @ts-ignore
        options.headers.set(
            'Authorization',
            `Bearer ${JSON.parse(auth).access_token}`
        );
    }
    // @ts-ignore
    return fetchUtils.fetchJson(url, options);
};

const Header = () => {
    const location = useLocation();
    const user = localStorage.getItem('auth');

    return (
        <>
            {(location.pathname !== '/dashboard' ||
                location.hash == '#/login' ||
                !user) && (
                <>
                    <header className="desktop">
                        <div className="logo">
                            <Link to="/">
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                        <nav>
                            <ul>
                                <li>
                                    <NavLink
                                        to="/about"
                                        activeClassName="active"
                                    >
                                        About
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/services"
                                        activeClassName="active"
                                    >
                                        Services
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/contact"
                                        activeClassName="active"
                                    >
                                        Contact Us
                                    </NavLink>
                                </li>
                                <li>
                                    <DashTitle />
                                </li>
                            </ul>
                        </nav>
                        <div className="right_menu">
                            <DashTitleOTP />
                        </div>
                    </header>
                    <header className="mobile">
                        <div className="hamburger-menu">
                            <input id="menu__toggle" type="checkbox" />
                            <label className="menu__btn" htmlFor="menu__toggle">
                                <span></span>
                            </label>
                            <ul className="menu__box">
                                <li>
                                    <NavLink
                                        to="/about"
                                        activeClassName="active"
                                    >
                                        About
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/services"
                                        activeClassName="active"
                                    >
                                        Services
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/contact"
                                        activeClassName="active"
                                    >
                                        Contact
                                    </NavLink>
                                </li>
                                <li>
                                    <DashTitle />
                                </li>
                                <li>
                                    <DashTitleOTP />
                                </li>
                            </ul>
                        </div>
                        <div className="logo">
                            <Link to="/">
                                <img src={logo} alt="logo" />
                            </Link>
                        </div>
                    </header>
                </>
            )}
        </>
    );
};

const Footer = () => {
    const location = useLocation();
    const user = localStorage.getItem('auth');

    return (
        <>
            {(location.pathname !== '/dashboard' ||
                location.hash == '#/login' ||
                !user) && (
                <>
                    <div className="footer">
                        <footer>
                            <div className="footer_left">
                                <div className="social">
                                    <div className="social_left">
                                        <a
                                            target="_blank"
                                            href="https://facebook.com"
                                        >
                                            Facebook
                                        </a>
                                        <a
                                            target="_blank"
                                            href="https://instagram.com"
                                        >
                                            Instagram
                                        </a>
                                    </div>
                                    <div className="social_right">
                                        <a
                                            target="_blank"
                                            href="https://linkedin.com"
                                        >
                                            Linkedin
                                        </a>
                                        <a
                                            target="_blank"
                                            href="https://twitter.com"
                                        >
                                            Twitter
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="footer_right">
                                <div className="phone_number">
                                    Tel:
                                    <a href="tel:+17472418169">747 241 8169</a>
                                </div>
                                <div className="phone_number">
                                    Fax:
                                    <a href="tel:+17472418198">747 241 8198</a>
                                </div>
                                <small className="address_field">
                                    704 S. Victory Blvd., Suite 102, Burbank
                                    91502, CA
                                </small>
                            </div>
                        </footer>
                        <div className="bottom">
                            <div className="copy_left">
                                By Mindify and davel creative agency
                            </div>
                            <div className="copy_right">
                                copyright Medex 2020. All rights reserved
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

const Notfound = () => {
    return (
        <>
            <div className="main_content">
                <h2 className="title">Requested page was not found</h2>
            </div>
        </>
    );
};

const Home = () => {
    return (
        <>
            <div className="parent_main">
                <div className="main_content">
                    <h1>Welcome to MEDEX</h1>
                    <Link to="/user">
                        <button className="check_esults medex_button">
                            check results
                        </button>
                    </Link>
                </div>
                <div className="main_bottom">
                    Our commitment to global health and innovation push us
                    towards new standards in specialized diagnostics. Take your
                    health tests and be assured you get the fastest and most
                    reliable test results possible.
                </div>
            </div>
        </>
    );
};

const About = () => {
    return (
        <>
            <div id="about">
                <div className="about_content content">
                    <div className="title">About</div>
                    <div className="text">
                        Medex laboratory provides laboratory services to both
                        individuals and medical clinics. Considering the
                        pandemic situation, patients can take COVID test at the
                        Medex laboratory. The patients register on the Medex
                        website only through the phone number, without
                        additional and time-consuming steps. Patients can find
                        their test result when they log in. If a patient takes a
                        COVID test in another clinic, then that particular
                        clinic gets an individual profile to check the test
                        results of their patients by contacting the Medex
                        laboratory.
                    </div>
                </div>
            </div>
        </>
    );
};

const DashTitle = () => {
    const user = localStorage.getItem('auth');
    return (
        <>
            {!user && (
                <NavLink to="/dashboard" activeClassName="active">
                    Partner Log-in
                </NavLink>
            )}
        </>
    );
};

const DashTitleOTP = () => {
    const user = localStorage.getItem('auth');
    return (
        <>
            {user ? (
                <NavLink
                    to={
                        JSON.parse(user).roles == 'user'
                            ? '/user'
                            : '/dashboard'
                    }
                    activeClassName="active"
                >
                    My Tests
                </NavLink>
            ) : (
                <NavLink to="/user" activeClassName="active">
                    Get Results
                </NavLink>
            )}
        </>
    );
};

const dataProvider = restProvider(
    process.env.REACT_APP_API_SERVER!,
    httpClient
);

interface AppProps {
    onUnmount: () => void;
    dataProvider: DataProvider;
}

const App = ({ onUnmount, dataProvider }: AppProps) => {
    useEffect(() => onUnmount, [onUnmount]);

    return (
        <div className="cont">
            <Particles
                params={{
                    particles: {
                        number: {
                            value: 60,
                            density: {
                                enable: true,
                                value_area: 1500,
                            },
                        },
                        color: {
                            value: '#ffffff',
                        },
                        shape: {
                            type: 'circle',
                            stroke: {
                                width: 2,
                                color: '#ffffff',
                            },
                            polygon: {
                                nb_sides: 10,
                            },
                        },
                        opacity: {
                            value: 0.3,
                            random: false,
                            anim: {
                                enable: false,
                                speed: 1,
                                opacity_min: 0.1,
                                sync: false,
                            },
                        },
                        size: {
                            value: 2,
                            random: true,
                            anim: {
                                enable: false,
                                speed: 40,
                                size_min: 0.1,
                                sync: false,
                            },
                        },
                        line_linked: {
                            enable: true,
                            distance: 150,
                            color: '#ffffff',
                            opacity: 0.4,
                            width: 1,
                        },
                        move: {
                            enable: true,
                            speed: 1,
                            direction: 'none',
                            random: false,
                            straight: false,
                            out_mode: 'out',
                            bounce: false,
                            attract: {
                                enable: false,
                                rotateX: 600,
                                rotateY: 1200,
                            },
                        },
                    },
                    interactivity: {
                        detect_on: 'canvas',
                        events: {
                            onhover: {
                                enable: true,
                                mode: 'grab',
                            },
                            // onclick: {
                            //     enable: true,
                            //     mode: 'push',
                            // },
                            resize: true,
                        },
                        modes: {
                            grab: {
                                distance: 140,
                                line_linked: {
                                    opacity: 1,
                                },
                            },
                            bubble: {
                                distance: 400,
                                size: 40,
                                duration: 2,
                                opacity: 8,
                                speed: 3,
                            },
                            repulse: {
                                distance: 200,
                                duration: 0.4,
                            },
                            push: {
                                particles_nb: 4,
                            },
                            remove: {
                                particles_nb: 2,
                            },
                        },
                    },
                    retina_detect: true,
                }}
            />
            <Router>
                <Header />
                <main>
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route path="/about">
                            <About />
                        </Route>
                        <Route path="/contact">
                            <Contact />
                        </Route>
                        <Route path="/services">
                            <Services />
                        </Route>
                        <Route path="/dashboard">
                            <Admin
                                title="MEDEX laboratory"
                                dataProvider={dataProvider}
                                customReducers={{ theme: themeReducer }}
                                customRoutes={customRoutes}
                                authProvider={authProvider}
                                // dashboard={Dashboard}
                                loginPage={Login}
                                layout={Layout}
                                i18nProvider={i18nProvider}
                                disableTelemetry
                            >
                                <Resource
                                    name="commands"
                                    {...orders}
                                    options={{ label: 'Orders' }}
                                />
                                <Resource name="customers" {...visitors} />
                                <Resource name="products" {...products} />
                                {/* <Resource name="invoices" {...invoices} />
                                <Resource name="categories" {...categories} />
                                <Resource name="reviews" {...reviews} /> */}
                            </Admin>
                        </Route>
                        <Route path="/user">
                            <Admin
                                title="MEDEX laboratory"
                                dataProvider={dataProvider}
                                customReducers={{ theme: themeReducer }}
                                customRoutes={customRoutes}
                                authProvider={otpAuthProvider}
                                // dashboard={Dashboard}
                                loginPage={LoginOTP}
                                layout={CustomLayout}
                                i18nProvider={i18nProvider}
                                disableTelemetry
                            >
                                {/* <Resource name="customers" {...visitors} /> */}
                                <Resource
                                    name="commands"
                                    {...lisOrders}
                                    options={{ label: 'Orders' }}
                                />
                                {/* 
                                <Resource name="invoices" {...invoices} />
                                <Resource name="products" {...products} />
                                <Resource name="categories" {...categories} />
                                <Resource name="reviews" {...reviews} /> */}
                            </Admin>
                        </Route>
                        <Route path="*">
                            <Notfound />
                        </Route>
                    </Switch>
                </main>
                <Footer />
            </Router>
        </div>
    );
};

export default App;
