import * as React from 'react';
import { useState, useEffect } from 'react';

const Services = () => {
    const [state, setState] = useState([]);

    // @ts-ignore
    useEffect(() => {
        const request = new Request(
            process.env.REACT_APP_API_SERVER! + '/services',
            {
                method: 'GET',
                // mode: 'cors',
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
            }
        );
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    return Promise.reject(response.statusText);
                }
                return response.json();
            })
            .then(data => {
                // @ts-ignore
                setState({ data: data });
                return Promise.resolve('Sucessfully Fetched');
            })
            .catch(() => {
                return Promise.reject('Network error');
            });
    }, []);

    return (
        <>
            <div className="content services">
                <div className="title">Services</div>
                <div className="box_container">
                    {
                        // @ts-ignore
                        state.data &&
                            // @ts-ignore
                            state.data.map(function (obj, i) {
                                // @ts-ignore
                                return (
                                    <div key={i} className="box">
                                        <a href={'#modal' + i}>
                                            <div className="">
                                                <div className="box_title">
                                                    {obj.title}
                                                </div>
                                                <div className="box_content">
                                                    {obj.description}
                                                </div>
                                            </div>
                                        </a>
                                        <div className="modal" id={'modal' + i}>
                                            <div className="modal-content">
                                                <a
                                                    className="modal-hide"
                                                    href="#"
                                                >
                                                    ✕
                                                </a>
                                                <div className="">
                                                    <div className="title title_popup">
                                                        {obj.title}
                                                    </div>
                                                    <div className="text">
                                                        {obj.description}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        </>
    );
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
// const ServicesWithTheme = (props: any) => (
//     <ThemeProvider theme={createMuiTheme(lightTheme)}>
//         <Services {...props} />
//     </ThemeProvider>
// );

export default Services;
