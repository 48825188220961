import * as React from 'react';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DatagridProps,
    DateField,
    DateInput,
    Filter,
    FilterProps,
    Identifier,
    List,
    ListContextProvider,
    ListProps,
    NullableBooleanInput,
    NumberField,
    ReferenceInput,
    ReferenceField,
    SearchInput,
    TextField,
    FileField,
    TextInput,
    useGetList,
    useListContext,
    usePermissions,
    useDataProvider,
    FunctionField,
    RefreshButton,
} from 'react-admin';
import LogoutButton from '../layout/LogoutButton';
import { useMediaQuery, Theme, Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import NbItemsField from './NbItemsField';
import CustomerReferenceField from '../visitors/CustomerReferenceField';
import DoctorReferenceField from '../visitors/DoctorReferenceField';
import AddressField from '../visitors/AddressField';
import MedexMobileGrid from './MedexMobileGrid';
import { Customer } from '../types';

const OrderFilter: FC<Omit<FilterProps, 'children'>> = props => (
    <Filter {...props}>
        {/* <SearchInput source="q" alwaysOn />
        <ReferenceInput source="customer_id" reference="customers">
            <AutocompleteInput
                optionText={(choice: Customer) =>
                    choice.id // the empty choice is { id: '' }
                        ? `${choice.first_name} ${choice.last_name}`
                        : ''
                }
            />
        </ReferenceInput>
        <DateInput source="date_gte" />
        <DateInput source="date_lte" /> */}
        {/* <TextInput source="total_gte" />
        <NullableBooleanInput source="returned" /> */}
    </Filter>
);

interface TabbedDatagridProps extends DatagridProps {}

const TabbedDatagrid: FC<TabbedDatagridProps> = props => {
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );

    const [loading, setLoading] = useState(false);

    // @ts-ignore
    const handleDownload = (id, test_id, phone) => {
        setLoading(true);
        const url =
            process.env.REACT_APP_API_SERVER! + '/commands/download-file';
        // @ts-ignore
        const auth = localStorage.getItem('auth');
        const options = {
            method: 'POST',
            mode: 'cors',
            // @ts-ignore
            body: JSON.stringify({
                id: id,
                test_id: test_id,
            }),
            headers: {
                'Content-Type': 'application/json',
                // @ts-ignore
                Authorization: 'Bearer ' + JSON.parse(auth).access_token,
            },
        };
        // @ts-ignore
        fetch(url, options)
            .then(res => res.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = phone + '-' + test_id + '.zip';
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove(); //afterwards we remove the element again
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                //Notify here
            });
    };

    return (
        <Fragment>
            <div className="mytest">
                <div className="title">My test results</div>
                <div className="name">
                    {
                        // @ts-ignore
                        localStorage.getItem('auth')
                            ? // @ts-ignore
                              JSON.parse(localStorage.getItem('auth')).fullname
                            : null
                    }
                    <RefreshButton />
                    <LogoutButton />
                </div>
                {isXSmall ? (
                    <MedexMobileGrid {...props} />
                ) : (
                    <div>
                        <Datagrid {...props} optimized>
                            <TextField
                                source="service_name"
                                label="Service Type"
                            />
                            <DateField source="date" showTime />
                            {/* <TextField source="doctor_name" /> */}
                            <TextField source="notes" />
                            <TextField source="status" />
                            <FunctionField
                                // @ts-ignore
                                render={record =>
                                    record.status == 'delivered' && (
                                        <Button
                                            name="download"
                                            type="submit"
                                            // disabled={downloading}
                                            onClick={e => {
                                                e.stopPropagation();
                                                handleDownload(
                                                    record.customer_id,
                                                    record.id,
                                                    record.phone
                                                );
                                            }}
                                        >
                                            {loading ? 'Loading' : 'Download'}
                                        </Button>
                                    )
                                }
                            />
                        </Datagrid>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

const OrderList: FC<ListProps> = props => (
    <List
        {...props}
        sort={{ field: 'date', order: 'DESC' }}
        perPage={5}
        filters={<OrderFilter />}
        bulkActionButtons={false}
        exporter={false}
        className="medex_table"
    >
        <TabbedDatagrid />
    </List>
);

export default OrderList;
