var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
// List of properties we expect in the options
var OptionsProperties = [
    'action',
    'fetch',
    'meta',
    'onFailure',
    'onSuccess',
    'undoable',
    'mutationMode',
    'enabled',
];
var isDataProviderOptions = function (value) {
    if (typeof value === 'undefined')
        return [];
    var options = value;
    return Object.keys(options).some(function (key) { return OptionsProperties.includes(key); });
};
// As all dataProvider methods do not have the same signature, we must differentiate
// standard methods which have the (resource, params, options) signature
// from the custom ones
export var getDataProviderCallArguments = function (args) {
    var lastArg = args[args.length - 1];
    var allArguments = __spreadArray([], args);
    var resource;
    var payload;
    var options;
    if (isDataProviderOptions(lastArg)) {
        options = lastArg;
        allArguments = allArguments.slice(0, args.length - 1);
    }
    if (typeof allArguments[0] === 'string') {
        resource = allArguments[0];
        payload = allArguments[1];
    }
    return {
        resource: resource,
        payload: payload,
        allArguments: allArguments,
        options: options,
    };
};
