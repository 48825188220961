var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useCallback, useEffect, useRef } from 'react';
import { useSafeSetState } from '../util';
var defaultSelection = [];
/**
 * Hooks to provide selection state.
 *
 * The names of the return values match the ListContext interface
 *
 * @example
 *
 * const { selectedIds, onSelect, onToggleItem, onUnselectItems } = useSelectionState();
 *
 */
var useSelectionState = function (initialSelection) {
    if (initialSelection === void 0) { initialSelection = defaultSelection; }
    var _a = useSafeSetState(initialSelection), selectedIds = _a[0], setSelectedIds = _a[1];
    var isFirstRender = useRef(true);
    useEffect(function () {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        setSelectedIds(initialSelection);
    }, [initialSelection, setSelectedIds]);
    var onSelect = useCallback(function (newIds) {
        setSelectedIds(newIds);
    }, [setSelectedIds]);
    var onToggleItem = useCallback(function (id) {
        setSelectedIds(function (previousState) {
            var index = previousState.indexOf(id);
            if (index > -1) {
                return __spreadArray(__spreadArray([], previousState.slice(0, index)), previousState.slice(index + 1));
            }
            else {
                return __spreadArray(__spreadArray([], previousState), [id]);
            }
        });
    }, [setSelectedIds]);
    var onUnselectItems = useCallback(function () {
        setSelectedIds([]);
    }, [setSelectedIds]);
    return {
        selectedIds: selectedIds,
        onSelect: onSelect,
        onToggleItem: onToggleItem,
        onUnselectItems: onUnselectItems,
    };
};
export default useSelectionState;
