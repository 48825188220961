var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { Component, createElement, useEffect, useRef, useState, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { createMuiTheme, withStyles, createStyles, } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { ComponentPropType } from 'ra-core';
import compose from 'lodash/flowRight';
import DefaultAppBar from './AppBar';
import DefaultSidebar from './Sidebar';
import DefaultMenu from './Menu';
import DefaultNotification from './Notification';
import DefaultError from './Error';
import defaultTheme from '../defaultTheme';
import SkipNavigationButton from '../button/SkipNavigationButton';
var styles = function (theme) {
    var _a, _b;
    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1,
            minHeight: '100vh',
            backgroundColor: theme.palette.background.default,
            position: 'relative',
            minWidth: 'fit-content',
            width: '100%',
            color: theme.palette.getContrastText(theme.palette.background.default),
        },
        appFrame: (_a = {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1
            },
            _a[theme.breakpoints.up('xs')] = {
                marginTop: theme.spacing(6),
            },
            _a[theme.breakpoints.down('xs')] = {
                marginTop: theme.spacing(7),
            },
            _a),
        contentWithSidebar: {
            display: 'flex',
            flexGrow: 1,
        },
        content: (_b = {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                flexBasis: 0,
                padding: theme.spacing(3),
                paddingTop: theme.spacing(1),
                paddingLeft: 0
            },
            _b[theme.breakpoints.up('xs')] = {
                paddingLeft: 5,
            },
            _b[theme.breakpoints.down('sm')] = {
                padding: 0,
            },
            _b),
    });
};
var LayoutWithoutTheme = /** @class */ (function (_super) {
    __extends(LayoutWithoutTheme, _super);
    function LayoutWithoutTheme(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { hasError: false, errorMessage: null, errorInfo: null };
        /**
         * Reset the error state upon navigation
         *
         * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
         */
        props.history.listen(function () {
            if (_this.state.hasError) {
                _this.setState({ hasError: false });
            }
        });
        return _this;
    }
    LayoutWithoutTheme.prototype.componentDidCatch = function (errorMessage, errorInfo) {
        this.setState({ hasError: true, errorMessage: errorMessage, errorInfo: errorInfo });
    };
    LayoutWithoutTheme.prototype.render = function () {
        var _a = this.props, appBar = _a.appBar, children = _a.children, classes = _a.classes, className = _a.className, error = _a.error, dashboard = _a.dashboard, logout = _a.logout, menu = _a.menu, notification = _a.notification, open = _a.open, sidebar = _a.sidebar, title = _a.title, 
        // sanitize react-router props
        match = _a.match, location = _a.location, history = _a.history, staticContext = _a.staticContext, props = __rest(_a, ["appBar", "children", "classes", "className", "error", "dashboard", "logout", "menu", "notification", "open", "sidebar", "title", "match", "location", "history", "staticContext"]);
        var _b = this.state, hasError = _b.hasError, errorMessage = _b.errorMessage, errorInfo = _b.errorInfo;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", __assign({ className: classnames('layout', classes.root, className) }, props),
                React.createElement(SkipNavigationButton, null),
                React.createElement("div", { className: classes.appFrame },
                    createElement(appBar, { title: title, open: open, logout: logout }),
                    React.createElement("main", { className: classes.contentWithSidebar },
                        createElement(sidebar, {
                            children: createElement(menu, {
                                logout: logout,
                                hasDashboard: !!dashboard,
                            }),
                        }),
                        React.createElement("div", { id: "main-content", className: classes.content }, hasError
                            ? createElement(error, {
                                error: errorMessage,
                                errorInfo: errorInfo,
                                title: title,
                            })
                            : children)))),
            createElement(notification)));
    };
    LayoutWithoutTheme.propTypes = {
        appBar: ComponentPropType,
        children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
        classes: PropTypes.object,
        className: PropTypes.string,
        dashboard: ComponentPropType,
        error: ComponentPropType,
        history: PropTypes.object.isRequired,
        logout: PropTypes.element,
        menu: ComponentPropType,
        notification: ComponentPropType,
        open: PropTypes.bool,
        sidebar: ComponentPropType,
        title: PropTypes.node.isRequired,
    };
    LayoutWithoutTheme.defaultProps = {
        appBar: DefaultAppBar,
        error: DefaultError,
        menu: DefaultMenu,
        notification: DefaultNotification,
        sidebar: DefaultSidebar,
    };
    return LayoutWithoutTheme;
}(Component));
var mapStateToProps = function (state) { return ({
    open: state.admin.ui.sidebarOpen,
}); };
var EnhancedLayout = compose(connect(mapStateToProps, {} // Avoid connect passing dispatch in props
), withRouter, withStyles(styles, { name: 'RaLayout' }))(LayoutWithoutTheme);
var Layout = function (_a) {
    var themeOverride = _a.theme, props = __rest(_a, ["theme"]);
    var themeProp = useRef(themeOverride);
    var _b = useState(createMuiTheme(themeOverride)), theme = _b[0], setTheme = _b[1];
    useEffect(function () {
        if (themeProp.current !== themeOverride) {
            themeProp.current = themeOverride;
            setTheme(createMuiTheme(themeOverride));
        }
    }, [themeOverride, themeProp, theme, setTheme]);
    return (React.createElement(ThemeProvider, { theme: theme },
        React.createElement(EnhancedLayout, __assign({}, props))));
};
Layout.propTypes = {
    theme: PropTypes.object,
};
Layout.defaultProps = {
    theme: defaultTheme,
};
export default Layout;
