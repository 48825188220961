import * as React from 'react';
import { FC } from 'react';
import {
    Create,
    CreateProps,
    DateInput,
    ImageInput,
    ImageField,
    FileInput,
    FileField,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    RadioButtonGroupInput,
    required,
    email,
    SelectInput,
    usePermissions,
} from 'react-admin';
import { AnyObject } from 'react-final-form';
import { Typography, Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';

export const styles: Styles<Theme, any> = {
    first_name: { display: 'inline-block' },
    last_name: { display: 'inline-block', marginLeft: 32 },
    email: { width: 544 },
    address: { maxWidth: 544 },
    zipcode: { display: 'inline-block' },
    city: { display: 'inline-block', marginLeft: 32 },
    comment: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    password: { display: 'inline-block' },
    confirm_password: { display: 'inline-block', marginLeft: 32 },
};

const useStyles = makeStyles(styles);

export const validatePasswords = ({
    password,
    confirm_password,
}: AnyObject) => {
    const errors = {} as any;

    if (password && confirm_password && password !== confirm_password) {
        errors.confirm_password = [
            'resources.customers.errors.password_mismatch',
        ];
    }

    return errors;
};

const OrderCreate: FC<CreateProps> = props => {
    const classes = useStyles(props);
    const { permissions } = usePermissions();
    return (
        <Create {...props}>
            <SimpleForm validate={validatePasswords}>
                <SectionTitle label="resources.customers.fieldGroups.identity" />
                {/* <TextInput
                    autoFocus
                    source="first_name"
                    formClassName={classes.first_name}
                    validate={requiredValidate}
                />
                <TextInput
                    source="last_name"
                    formClassName={classes.last_name}
                    validate={requiredValidate}
                /> */}
                <TextInput
                    type="text"
                    source="mobile"
                    // validation={{ email: true }}
                    fullWidth
                    formClassName={classes.email}
                    validate={[required()]}
                />
                {/* <TextInput
                    type="email"
                    source="email"
                    validation={{ email: true }}
                    fullWidth
                    formClassName={classes.email}
                    validate={[email()]}
                /> */}
                {/* <DateInput source="birthday" /> */}
                <Separator />
                <SectionTitle label="Test" />
                {permissions == 'medex_representative' && (
                    <RadioButtonGroupInput
                        source="service_type"
                        choices={[
                            { id: 'personal', name: 'Personal' },
                            { id: 'corporate', name: 'Corporate' },
                        ]}
                    />
                )}
                <SelectInput
                    resource="commands"
                    source="service_id"
                    validate={[required()]}
                    choices={[
                        {
                            id: '1',
                            name: 'Molecular Probe Based Testing',
                        },
                        {
                            id: '2',
                            name:
                                'FilmArray Pneumonia (PN) Panel Test or PCR Testing',
                        },
                    ]}
                />
                {/* <TextInput
                    type="text"
                    source="service_name"
                    fullWidth
                    formClassName={classes.email}
                /> */}
                {/* <SelectInput
                    resource="commands"
                    source="status"
                    choices={[
                        {
                            id: 'ordered',
                            name: 'ordered',
                        },
                        {
                            id: 'delivered',
                            name: 'delivered',
                        },
                        {
                            id: 'cancelled',
                            name: 'cancelled',
                        },
                    ]}
                /> */}
                <TextInput
                    type="text"
                    source="notes"
                    fullWidth
                    multiline
                    formClassName={classes.email}
                />
                {permissions == 'medex_representative' && (
                    <FileInput
                        multiple
                        source="attachments"
                        accept="application/pdf"
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                )}
                {/* <ImageInput
                    source="attachments"
                    label="Test results"
                    accept="image/png"
                    multiple={true}
                >
                    <ImageField source="src" title="title" />
                </ImageInput> */}
            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default OrderCreate;
