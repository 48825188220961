import * as React from 'react';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import {
    AutocompleteInput,
    BooleanField,
    Datagrid,
    DatagridProps,
    DateField,
    DateInput,
    Filter,
    FilterProps,
    Identifier,
    List,
    ListContextProvider,
    ListProps,
    NullableBooleanInput,
    NumberField,
    ReferenceInput,
    ReferenceField,
    SearchInput,
    TextField,
    TextInput,
    useGetList,
    useListContext,
    usePermissions,
    FunctionField,
} from 'react-admin';
import {
    useMediaQuery,
    Divider,
    Tabs,
    Tab,
    Theme,
    Button,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import NbItemsField from './NbItemsField';
import CustomerReferenceField from '../visitors/CustomerReferenceField';
import DoctorReferenceField from '../visitors/DoctorReferenceField';
import AddressField from '../visitors/AddressField';
import MobileGrid from './MobileGrid';
import { Customer } from '../types';

const OrderFilter: FC<Omit<FilterProps, 'children'>> = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn placeholder="Search by phone" />
        <ReferenceInput source="customer_id" reference="customers">
            <AutocompleteInput
                optionText={(choice: Customer) =>
                    choice.id // the empty choice is { id: '' }
                        ? `${choice.first_name} ${choice.last_name}`
                        : ''
                }
            />
        </ReferenceInput>
        <DateInput source="date_gte" />
        <DateInput source="date_lte" />
        {/* <TextInput source="total_gte" />
        <NullableBooleanInput source="returned" /> */}
    </Filter>
);

const useDatagridStyles = makeStyles({
    total: { fontWeight: 'bold' },
});

const tabs = [
    { id: 'ordered', name: 'ordered' },
    { id: 'delivered', name: 'delivered' },
    { id: 'cancelled', name: 'cancelled' },
];

interface TabbedDatagridProps extends DatagridProps {}

const useGetTotals = (filterValues: any) => {
    const { total: totalOrdered } = useGetList(
        'commands',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'ordered' }
    );
    const { total: totalDelivered } = useGetList(
        'commands',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'delivered' }
    );
    const { total: totalCancelled } = useGetList(
        'commands',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'cancelled' }
    );

    return {
        ordered: totalOrdered,
        delivered: totalDelivered,
        cancelled: totalCancelled,
    };
};

const TabbedDatagrid: FC<TabbedDatagridProps> = props => {
    const listContext = useListContext();
    const { ids, filterValues, setFilters, displayedFilters } = listContext;
    const classes = useDatagridStyles();
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('xs')
    );
    const { permissions } = usePermissions();
    const [ordered, setOrdered] = useState<Identifier[]>([] as Identifier[]);
    const [delivered, setDelivered] = useState<Identifier[]>(
        [] as Identifier[]
    );
    const [cancelled, setCancelled] = useState<Identifier[]>(
        [] as Identifier[]
    );
    const totals = useGetTotals(filterValues) as any;

    const [loading, setLoading] = useState(false);

    // @ts-ignore
    const handleDownload = (id, test_id, phone) => {
        setLoading(true);
        const url =
            process.env.REACT_APP_API_SERVER! + '/commands/download-file';
        // @ts-ignore
        const auth = localStorage.getItem('auth');
        const options = {
            method: 'POST',
            mode: 'cors',
            // @ts-ignore
            body: JSON.stringify({
                id: id,
                test_id: test_id,
            }),
            headers: {
                'Content-Type': 'application/json',
                // @ts-ignore
                Authorization: 'Bearer ' + JSON.parse(auth).access_token,
            },
        };
        // @ts-ignore
        fetch(url, options)
            .then(res => res.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = phone + '-' + test_id + '.zip';
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove(); //afterwards we remove the element again
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                //Notify here
            });
    };

    useEffect(() => {
        if (ids && ids !== filterValues.status) {
            switch (filterValues.status) {
                case 'ordered':
                    setOrdered(ids);
                    break;
                case 'delivered':
                    setDelivered(ids);
                    break;
                case 'cancelled':
                    setCancelled(ids);
                    break;
            }
        }
    }, [ids, filterValues.status]);

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
                setFilters(
                    { ...filterValues, status: value },
                    displayedFilters
                );
        },
        [displayedFilters, filterValues, setFilters]
    );

    const selectedIds =
        filterValues.status === 'ordered'
            ? ordered
            : filterValues.status === 'delivered'
            ? delivered
            : cancelled;

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.status}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            totals[choice.name]
                                ? `${choice.name} (${totals[choice.name]})`
                                : choice.name
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider />
            {isXSmall ? (
                <ListContextProvider
                    value={{ ...listContext, ids: selectedIds }}
                >
                    <MobileGrid {...props} ids={selectedIds} />
                </ListContextProvider>
            ) : (
                <div>
                    {filterValues.status === 'ordered' && (
                        <ListContextProvider
                            value={{ ...listContext, ids: ordered }}
                        >
                            <Datagrid {...props} optimized rowClick="edit">
                                {permissions === 'user' ? (
                                    <TextField source="name" />
                                ) : (
                                    <CustomerReferenceField />
                                )}
                                <TextField
                                    source="service_name"
                                    label="Service Type"
                                />
                                <TextField source="phone" />
                                <DateField source="date" showTime />
                                {/* <TextField source="reference" /> */}
                                {/* <ReferenceField
                                    source="customer_id"
                                    reference="customers"
                                    link={false}
                                    label="resources.commands.fields.address"
                                >
                                    <AddressField />
                                </ReferenceField> */}
                                <DateField source="dob" label="Birth Date" />
                                {permissions === 'medex_representative' && (
                                    <DoctorReferenceField label="Partner name" />
                                )}
                                <FunctionField
                                    // @ts-ignore
                                    render={record =>
                                        record.status == 'delivered' && (
                                            <Button
                                                name="download"
                                                type="submit"
                                                // disabled={downloading}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    handleDownload(
                                                        record.customer_id,
                                                        record.id,
                                                        record.phone
                                                    );
                                                }}
                                            >
                                                {loading
                                                    ? 'Loading'
                                                    : 'Download'}
                                            </Button>
                                        )
                                    }
                                />
                                {/* <NbItemsField />
                                <NumberField
                                    source="total"
                                    options={{
                                        style: 'currency',
                                        currency: 'USD',
                                    }}
                                    className={classes.total}
                                /> */}
                            </Datagrid>
                        </ListContextProvider>
                    )}
                    {filterValues.status === 'delivered' && (
                        <ListContextProvider
                            value={{ ...listContext, ids: delivered }}
                        >
                            <Datagrid {...props} rowClick="edit">
                                <CustomerReferenceField />
                                <TextField
                                    source="service_name"
                                    label="Service Type"
                                />
                                <TextField source="phone" />
                                <DateField source="date" showTime />
                                <DateField source="dob" label="Birth Date" />
                                {permissions === 'medex_representative' && (
                                    <DoctorReferenceField label="Partner name" />
                                )}
                                <FunctionField
                                    // @ts-ignore
                                    render={record =>
                                        record.status == 'delivered' && (
                                            <Button
                                                name="download"
                                                type="submit"
                                                // disabled={downloading}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    handleDownload(
                                                        record.customer_id,
                                                        record.id,
                                                        record.phone
                                                    );
                                                }}
                                            >
                                                {loading
                                                    ? 'Loading'
                                                    : 'Download'}
                                            </Button>
                                        )
                                    }
                                />
                                {/* <DateField source="date" showTime />
                                <TextField source="reference" />
                                <CustomerReferenceField />
                                <ReferenceField
                                    source="customer_id"
                                    reference="customers"
                                    link={false}
                                    label="resources.commands.fields.address"
                                >
                                    <AddressField />
                                </ReferenceField>
                                <NbItemsField />
                                <NumberField
                                    source="total"
                                    options={{
                                        style: 'currency',
                                        currency: 'USD',
                                    }}
                                    className={classes.total}
                                />
                                <BooleanField source="returned" /> */}
                            </Datagrid>
                        </ListContextProvider>
                    )}
                    {filterValues.status === 'cancelled' && (
                        <ListContextProvider
                            value={{ ...listContext, ids: cancelled }}
                        >
                            <Datagrid {...props} rowClick="edit">
                                <CustomerReferenceField />
                                <TextField
                                    source="service_name"
                                    label="Service Type"
                                />
                                <TextField source="phone" />
                                <DateField source="date" showTime />
                                <DateField source="dob" label="Birth Date" />
                                {permissions === 'medex_representative' && (
                                    <DoctorReferenceField label="Partner name" />
                                )}
                                <FunctionField
                                    // @ts-ignore
                                    render={record =>
                                        record.status == 'delivered' && (
                                            <Button
                                                name="download"
                                                type="submit"
                                                // disabled={downloading}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    handleDownload(
                                                        record.customer_id,
                                                        record.id,
                                                        record.phone
                                                    );
                                                }}
                                            >
                                                {loading
                                                    ? 'Loading'
                                                    : 'Download'}
                                            </Button>
                                        )
                                    }
                                />
                                {/* <DateField source="date" showTime />
                                <TextField source="reference" />
                                <CustomerReferenceField />
                                <ReferenceField
                                    source="customer_id"
                                    reference="customers"
                                    link={false}
                                    label="resources.commands.fields.address"
                                >
                                    <AddressField />
                                </ReferenceField>
                                <NbItemsField />
                                <NumberField
                                    source="total"
                                    options={{
                                        style: 'currency',
                                        currency: 'USD',
                                    }}
                                    className={classes.total}
                                />
                                <BooleanField source="returned" /> */}
                            </Datagrid>
                        </ListContextProvider>
                    )}
                </div>
            )}
        </Fragment>
    );
};

const OrderList: FC<ListProps> = props => (
    <List
        {...props}
        filterDefaultValues={{ status: 'ordered' }}
        sort={{ field: 'date', order: 'DESC' }}
        perPage={10}
        filters={<OrderFilter />}
    >
        <TabbedDatagrid />
    </List>
);

export default OrderList;
