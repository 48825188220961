import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@material-ui/core/styles';

const Logo = (props: SVGProps<SVGSVGElement>) => {
    const theme = useTheme();
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            width="264.001"
            height="68.584"
            viewBox="0 0 264.001 68.584"
        >
            <path
                id="Path_1"
                data-name="Path 1"
                d="M46.587,542.978A7.543,7.543,0,0,0,44,542.53H7.77a7.613,7.613,0,0,0-2.59.448A7.756,7.756,0,0,0,0,550.3v18.118H5.18V550.3a2.583,2.583,0,0,1,2.59-2.589H44a2.58,2.58,0,0,1,2.586,2.551v18.156h5.186V550.3A7.756,7.756,0,0,0,46.587,542.978Z"
                transform="translate(0 -527.003)"
                fill="#0c32ae"
            />
            <rect
                id="Rectangle_3"
                data-name="Rectangle 3"
                width="5.176"
                height="15.531"
                transform="translate(23.297 25.881)"
                fill="#0c32ae"
            />
            <path
                id="Path_2"
                data-name="Path 2"
                d="M2022.705,542.531h-25.551a7.537,7.537,0,0,0-2.369.436h-.019a7.756,7.756,0,0,0-5.18,7.321v10.348a7.758,7.758,0,0,0,5.175,7.322,7.613,7.613,0,0,0,2.59.448h32.781V563.6H1997.3a2.537,2.537,0,0,1-2.536-2.486v-3.49h0V550.3a2.584,2.584,0,0,1,2.6-2.586h25.111a3.291,3.291,0,1,1,0,6.582h-22.53v4.822h22.764a8.29,8.29,0,1,0,0-16.579Z"
                transform="translate(-1932.643 -527.004)"
                fill="#0c32ae"
            />
            <path
                id="Path_3"
                data-name="Path 3"
                d="M5647.361,568.615v-4.822h-32.833a2.538,2.538,0,0,1-2.536-2.486v-3.49h0v-7.323a2.583,2.583,0,0,1,2.587-2.586H5639.7a3.291,3.291,0,1,1,0,6.582h-22.525v4.823h22.764a8.29,8.29,0,0,0,0-16.579h-25.548a7.546,7.546,0,0,0-2.37.436H5612a7.754,7.754,0,0,0-5.179,7.321V560.84a7.75,7.75,0,0,0,5.174,7.328,7.613,7.613,0,0,0,2.59.448Z"
                transform="translate(-5446.347 -527.201)"
                fill="#0c32ae"
            />
            <path
                id="Path_4"
                data-name="Path 4"
                d="M3669.117,28.471V0h-5.172V28.471a7.77,7.77,0,0,1-7.765,7.763h-25.887a7.764,7.764,0,0,1-.2-15.527h21.045a2.59,2.59,0,0,1,2.448,2.583v4h5.175v-4a7.554,7.554,0,0,0-.448-2.583,7.738,7.738,0,0,0-4.727-4.732,7.616,7.616,0,0,0-2.589-.448h-20.7a12.843,12.843,0,0,0-7.77,2.59,13.155,13.155,0,0,0-2.59,2.59,12.943,12.943,0,0,0,10.359,20.7h25.886a13.65,13.65,0,0,0,2.579-.252,12.966,12.966,0,0,0,10.353-12.685Z"
                transform="translate(-3513.825)"
                fill="#0c32ae"
            />
            <path
                id="Path_5"
                data-name="Path 5"
                d="M7275.881,568.412h15.524v-5.18h-15.666a2.73,2.73,0,0,1-2.654-2.084.394.394,0,0,0-.02-.092,10.387,10.387,0,0,0-3.1-5.433c-.058-.052-.121-.1-.181-.145.061-.052.124-.1.181-.153a10.387,10.387,0,0,0,3.1-5.433.566.566,0,0,1,.02-.092,2.728,2.728,0,0,1,2.654-2.082h15.666v-5.186h-15.524a7.738,7.738,0,0,0-7.314,5.18l-.234.778a1.573,1.573,0,0,1-.046.2,5.486,5.486,0,0,1-5.059,4.217c-.1,0-.192-.013-.29-.013s-.192.012-.292.013a5.49,5.49,0,0,1-5.061-4.217c-.019-.064-.032-.129-.044-.2l-.235-.778a7.736,7.736,0,0,0-7.316-5.18h-15.526v5.18h15.674a2.731,2.731,0,0,1,2.655,2.083.466.466,0,0,0,.019.092,10.382,10.382,0,0,0,3.1,5.433c.058.054.121.1.182.153-.061.051-.124.1-.182.145a10.383,10.383,0,0,0-3.1,5.433.793.793,0,0,1-.019.092,2.733,2.733,0,0,1-2.655,2.084h-15.674v5.18H7250a7.74,7.74,0,0,0,7.314-5.18l.233-.78a1.8,1.8,0,0,1,.045-.195,5.492,5.492,0,0,1,5.062-4.218c.095,0,.189.013.289.013s.193-.01.291-.013a5.488,5.488,0,0,1,5.061,4.218,1.278,1.278,0,0,1,.044.195l.235.78a7.745,7.745,0,0,0,7.315,5.18Z"
                transform="translate(-7027.407 -527.003)"
                fill="#0c32ae"
            />
            <rect
                id="Rectangle_4"
                data-name="Rectangle 4"
                width="2.589"
                height="16.822"
                transform="translate(146.256 46.586)"
                fill="#0c32ae"
            />
            <path
                id="Path_6"
                data-name="Path 6"
                d="M7128.792,1763.419a6.472,6.472,0,1,0,0,12.943h2.593v-2.589h-2.6a3.886,3.886,0,1,1,3.882-4.018v6.6h2.581v-6.471a6.463,6.463,0,0,0-6.463-6.466Z"
                transform="translate(-6918.473 -1712.947)"
                fill="#0c32ae"
            />
            <path
                id="Path_7"
                data-name="Path 7"
                d="M5795.346,1631.533h-2.6v2.584h2.6a3.886,3.886,0,1,1-3.875,4.1v-10.57h-2.588v10.36a6.463,6.463,0,1,0,6.463-6.473Z"
                transform="translate(-5623.201 -1581.06)"
                fill="#0c32ae"
            />
            <path
                id="Path_8"
                data-name="Path 8"
                d="M5275.079,1763.419a6.472,6.472,0,1,0,0,12.943h2.6v-2.589h-2.6a3.886,3.886,0,1,1,3.881-4.018v6.6h2.582v-6.471a6.459,6.459,0,0,0-6.463-6.466Z"
                transform="translate(-5117.814 -1712.947)"
                fill="#0c32ae"
            />
            <path
                id="Path_9"
                data-name="Path 9"
                d="M6315.172,1763.469a6.468,6.468,0,1,0,6.466,6.466A6.466,6.466,0,0,0,6315.172,1763.469Zm0,10.354a3.886,3.886,0,1,1,2.751-1.138A3.885,3.885,0,0,1,6315.172,1773.823Z"
                transform="translate(-6128.141 -1712.997)"
                fill="#0c32ae"
            />
            <path
                id="Path_10"
                data-name="Path 10"
                d="M7986.668,1763.469a6.469,6.469,0,1,0,6.466,6.466A6.468,6.468,0,0,0,7986.668,1763.469Zm0,10.354a3.883,3.883,0,1,1,2.752-1.138A3.887,3.887,0,0,1,7986.668,1773.823Z"
                transform="translate(-7751.797 -1712.997)"
                fill="#0c32ae"
            />
            <path
                id="Path_11"
                data-name="Path 11"
                d="M6858.508,1763.222h0a6.423,6.423,0,0,0-3.876,1.3v-1.3h-2.589v12.936h2.589V1769.6a3.881,3.881,0,0,1,3.876-3.79h.653v-2.592h-.646Z"
                transform="translate(-6655.932 -1712.75)"
                fill="#0c32ae"
            />
            <path
                id="Path_12"
                data-name="Path 12"
                d="M8529.752,1763.222h0a6.426,6.426,0,0,0-3.875,1.3v-1.3h-2.589v12.936h2.589V1769.6a3.883,3.883,0,0,1,3.875-3.79h.653v-2.592h-.645Z"
                transform="translate(-8279.344 -1712.75)"
                fill="#0c32ae"
            />
            <path
                id="Path_13"
                data-name="Path 13"
                d="M7668.734,1641.88a1.293,1.293,0,0,1-1.294-1.292v-6.47h5.18v-2.593h-5.18v-3.88h-2.59v12.943a3.711,3.711,0,0,0,.228,1.292,3.846,3.846,0,0,0,2.362,2.363,3.694,3.694,0,0,0,1.294.227h3.887v-2.59Z"
                transform="translate(-7445.475 -1581.06)"
                fill="#0c32ae"
            />
            <path
                id="Path_14"
                data-name="Path 14"
                d="M8870.032,1763.469v7.764a2.591,2.591,0,0,1-5.183,0v-7.764h-2.588v7.757a5.175,5.175,0,0,0,2.588,4.486,5.071,5.071,0,0,0,1.3.525v5.344h2.586v-5.342a5.078,5.078,0,0,0,1.3-.527,5.187,5.187,0,0,0,2.584-4.486v-7.757Z"
                transform="translate(-8608.615 -1712.997)"
                fill="#0c32ae"
            />
        </svg>
    );
};

export default Logo;
