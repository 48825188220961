var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import uniq from 'lodash/uniq';
import { CRUD_GET_LIST_SUCCESS, CRUD_CREATE_SUCCESS, } from '../../../../actions';
import { DELETE, DELETE_MANY } from '../../../../core';
var initialState = [];
/**
 * List of the ids of the latest loaded page, regardless of params
 *
 * When loading the list for the first time, useListController grabs the ids
 * from the cachedRequests reducer (not this ids reducer). It's only when the user
 * changes page, sort, or filter, that the useListController hook uses the ids
 * reducer, so as to show the previous list of results while loading the new
 * list (instead of displaying a blank page each time the list params change).
 *
 * @see useListController
 *
 */
var idsReducer = function (previousState, action) {
    if (previousState === void 0) { previousState = initialState; }
    if (action.meta && action.meta.optimistic) {
        if (action.meta.fetch === DELETE) {
            var index = previousState
                .map(function (el) { return el === action.payload.id; }) // eslint-disable-line eqeqeq
                .indexOf(true);
            if (index === -1) {
                return previousState;
            }
            return __spreadArray(__spreadArray([], previousState.slice(0, index)), previousState.slice(index + 1));
        }
        if (action.meta.fetch === DELETE_MANY) {
            var newState = previousState.filter(function (el) { return !action.payload.ids.includes(el); });
            return newState;
        }
    }
    switch (action.type) {
        case CRUD_GET_LIST_SUCCESS:
            return action.payload.data.map(function (_a) {
                var id = _a.id;
                return id;
            });
        case CRUD_CREATE_SUCCESS:
            return uniq(__spreadArray([action.payload.data.id], previousState));
        default:
            return previousState;
    }
};
export default idsReducer;
export var getIds = function (state) { return state; };
