import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
    Layout,
    LayoutProps,
    Sidebar,
    setSidebarVisibility,
} from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { darkTheme, lightTheme } from './themes';
import { AppState } from '../types';

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />;

export default (props: LayoutProps) => {
    const theme = useSelector((state: AppState) =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setSidebarVisibility(false));
    });
    return (
        <div className="dashboard_start">
            <Layout
                {...props}
                appBar={AppBar}
                sidebar={CustomSidebar}
                menu={Menu}
                theme={theme}
            />
        </div>
    );
};
