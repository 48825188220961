import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';

import { CardActions, CircularProgress, TextField } from '@material-ui/core';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { lightTheme } from './themes';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        // minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        // background: 'url(https://source.unsplash.com/random/1600x900)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
}));

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        className="inp"
        {...inputProps}
        {...props}
        fullWidth
    />
);

interface FormValues {
    email?: string;
    name?: string;
    mobile?: string;
    message?: string;
}

const { Form } = withTypes<FormValues>();

const Contact = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [submited, setSubmited] = useState(false);
    const classes = useStyles();

    const handleSubmit = (values: FormValues) => {
        setLoading(true);
        const request = new Request(
            process.env.REACT_APP_API_SERVER! + '/contact',
            {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                    // @ts-ignore
                    name: values.name,
                    // @ts-ignore
                    email: values.email,
                    // @ts-ignore
                    mobile: values.mobile,
                    // @ts-ignore
                    message: values.message,
                }),
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
            }
        );
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    setLoading(false);
                    setError(true);
                    setSubmited(false);
                    return Promise.reject(response.statusText);
                }
                setLoading(false);
                setError(false);
                setSubmited(true);
                return Promise.resolve('Sucessfully Sent');
            })
            .catch(() => {
                setLoading(false);
                setError(true);
                setSubmited(false);
                return Promise.reject('Network error');
            });
    };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};
        if (!values.email) {
            errors.email = 'E-mail is required';
        }
        if (!values.name) {
            errors.name = 'Name Surname is required';
        }
        if (!values.mobile) {
            errors.mobile = 'Mobile number is required';
        }
        if (!values.message) {
            errors.message = 'Message is required';
        }
        // if (values.mobile) {
        //     var phoneno = /^(\+{1})?(1\s?)?(\([0-9]{3}\)|[0-9]{3})?([\s\-]{1})?[0-9]{3}([\s\-]{1})?[0-9]{4}$/;
        //     if (!values.mobile.match(phoneno)) {
        //         errors.mobile = 'Not a valid number';
        //     }
        // }
        // if (values.email) {
        //     var validemail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        //     if (!values.email.match(validemail)) {
        //         errors.email = 'Not a valid e-mail address';
        //     }
        // }

        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <>
                    {!submited ? (
                        <div className="content contact">
                            <form onSubmit={handleSubmit} noValidate>
                                <div className="title">Complete the form</div>
                                <div className={classes.main}>
                                    <div className="login_container contact_form">
                                        <div className="tel label">
                                            <Field
                                                autoFocus
                                                name="name"
                                                // @ts-ignore
                                                component={renderInput}
                                                label="Name"
                                                disabled={loading}
                                            />
                                        </div>
                                        <div className="tel label">
                                            <Field
                                                name="mobile"
                                                // @ts-ignore
                                                component={renderInput}
                                                label="Mobile"
                                                disabled={loading}
                                            />
                                        </div>
                                        <div className="tel label">
                                            <Field
                                                name="email"
                                                // @ts-ignore
                                                component={renderInput}
                                                label="E-mail"
                                                disabled={loading}
                                            />
                                        </div>
                                        <div className="tel label">
                                            <Field
                                                name="message"
                                                // @ts-ignore
                                                component={renderInput}
                                                label="Message"
                                                disabled={loading}
                                            />
                                        </div>
                                    </div>
                                    {error ? (
                                        <div className="error_text">
                                            There was an error processing your
                                            request.
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <CardActions className={classes.actions}>
                                        <button
                                            className="medex_button"
                                            type="submit"
                                            disabled={loading}
                                        >
                                            {loading && (
                                                <CircularProgress
                                                    size={25}
                                                    thickness={2}
                                                />
                                            )}
                                            Submit
                                        </button>
                                    </CardActions>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div className="content contact">
                            {/* <div className="title">Contact</div> */}
                            <h2 className="title">
                                Thank You for contacting us
                            </h2>
                        </div>
                    )}
                </>
            )}
        />
    );
};

Contact.propTypes = {
    dataProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const ContactWithTheme = (props: any) => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <Contact {...props} />
    </ThemeProvider>
);

export default ContactWithTheme;
