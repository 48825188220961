// @ts-nocheck
import { CollectionsOutlined } from '@material-ui/icons';
import { encode as base64_encode } from 'base-64';

const otpAuthProvider = {
    login: ({ username, birthday, password }) => {
        const authorize = password
            ? base64_encode(username + ':' + password)
            : null;
        const url = password
            ? process.env.REACT_APP_API_SERVER + '/user'
            : process.env.REACT_APP_API_SERVER + '/otp';
        const body = JSON.stringify({
            username: username,
            dob: birthday,
        });
        const request = new Request(url, {
            method: 'POST',
            mode: 'cors',
            body: body,
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization: 'Basic ' + authorize,
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                if ('access_token' in auth) {
                    localStorage.setItem('auth', JSON.stringify(auth));
                } else if ('success' in auth) {
                    localStorage.setItem('otp', true);
                    throw new Error('OTP sent');
                } else {
                    throw new Error('Invalid credentials supplied');
                }
            })
            .catch(() => {
                if (localStorage.getItem('otp')) {
                    throw new Error('OTP sent');
                }
                throw new Error('Invalid credentials supplied');
            });
    },
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    checkError: ({ error }) => {
        if (error) {
            const status = error.status;
            if (status === 401 || status === 403) {
                localStorage.removeItem('auth');
                return Promise.reject();
            }
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () =>
        localStorage.getItem('auth') ? Promise.resolve() : Promise.reject(),
    getPermissions: () => {
        if (JSON.parse(localStorage.getItem('auth'))) {
            const role = JSON.parse(localStorage.getItem('auth')).roles;
            return role ? Promise.resolve(role) : Promise.reject();
        } else {
            return Promise.reject();
        }
    },
    getIdentity: () =>
        localStorage.getItem('auth')
            ? Promise.resolve({
                  id: JSON.parse(localStorage.getItem('auth')).username,
                  fullName: JSON.parse(localStorage.getItem('auth')).username,
                  avatar: JSON.parse(localStorage.getItem('auth')).avatar,
              })
            : Promise.reject(),
};

export default otpAuthProvider;
