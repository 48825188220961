import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { useLocation } from 'react-router-dom';
// @ts-ignore
import { encode as base64_encode } from 'base-64';

import {
    Avatar,
    Button,
    Card,
    CardActions,
    CircularProgress,
    TextField,
} from '@material-ui/core';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import {
    DateInput,
    Notification,
    useTranslate,
    useLogin,
    useNotify,
} from 'react-admin';

import { lightTheme } from './themes';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        // minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        // background: 'url(https://source.unsplash.com/random/1600x900)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
}));

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

interface FormValues {
    username?: string;
    birthday?: string;
    password?: string;
}

const { Form } = withTypes<FormValues>();

const LoginOTP = () => {
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation<{ nextPathname: string } | null>();

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login(auth, location.state ? location.state.nextPathname : '/').catch(
            (error: Error) => {
                if (localStorage.getItem('otp')) {
                    localStorage.removeItem('otp');
                    setOtp(true);
                    notify('OTP Sent', 'info', {}, false);
                } else {
                    if (!auth.password) {
                        setOtp(false);
                    }
                    notify(
                        typeof error === 'string'
                            ? error
                            : typeof error === 'undefined' || !error.message
                            ? 'ra.auth.sign_in_error'
                            : error.message,
                        'warning',
                        {
                            _:
                                typeof error === 'string'
                                    ? error
                                    : error && error.message
                                    ? error.message
                                    : undefined,
                        }
                    );
                }
                setLoading(false);
            }
        );
    };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};
        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        // if (values.username) {
        //     var phoneno = /^(\+{1})?(1\s?)?(\([0-9]{3}\)|[0-9]{3})?([\s\-]{1})?[0-9]{3}([\s\-]{1})?[0-9]{4}$/;
        //     if (!values.username.match(phoneno)) {
        //         errors.username = 'Not a valid number';
        //     }
        // }
        if (!values.birthday) {
            errors.birthday = translate('ra.validation.required');
        }
        if (otp && !values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit, values }) => (
                <div className="content contact">
                    <form onSubmit={handleSubmit} noValidate>
                        <div className="title">LOG-IN TO CONTINUE</div>
                        <div className={classes.main}>
                            <div className="login_container contact_form">
                                <div className="tel label">
                                    <Field
                                        autoFocus
                                        name="username"
                                        // @ts-ignore
                                        component={renderInput}
                                        label="Mobile number"
                                        disabled={otp || loading}
                                    />
                                </div>
                                <div className="tel label">
                                    <DateInput
                                        source="birthday"
                                        label="Date of birh"
                                        name="birthday"
                                        isRequired={true}
                                        disabled={otp || loading}
                                    />
                                </div>
                                {otp ? (
                                    <div className="tel label">
                                        <Field
                                            name="password"
                                            // @ts-ignore
                                            component={renderInput}
                                            label="One Time Password"
                                            type="password"
                                            disabled={!otp || loading}
                                        />
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <CardActions className={classes.actions}>
                                <button
                                    className="medex_button"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    {otp ? 'Log-in' : 'Send Code'}
                                </button>
                            </CardActions>
                            <Notification />
                        </div>
                    </form>
                </div>
            )}
        />
    );
};

LoginOTP.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginOTPWithTheme = (props: any) => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <LoginOTP {...props} />
    </ThemeProvider>
);

export default LoginOTPWithTheme;
