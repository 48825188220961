import * as React from 'react';
import { FC, useState } from 'react';
import {
    BooleanInput,
    DateField,
    Edit,
    EditProps,
    FormWithRedirect,
    Labeled,
    ReferenceField,
    SelectInput,
    TextField,
    Toolbar,
    useTranslate,
    RadioButtonGroupInput,
    required,
    email,
    usePermissions,
    FileInput,
    FileField,
    TextInput,
    FunctionField,
} from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
import {
    Card,
    CardContent,
    Box,
    Grid,
    Typography,
    Link,
    Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Order, Customer } from '../types';
import Basket from './Basket';
import Totals from './Totals';
import resource from '../visitors';

interface OrderTitleProps {
    record?: Order;
}

const OrderTitle: FC<OrderTitleProps> = ({ record }) => {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.commands.title', {
                reference: record.reference,
            })}
        </span>
    ) : null;
};

const CustomerDetails = ({ record }: { record?: Customer }) => (
    <Box display="flex" flexDirection="column">
        <Typography
            component={RouterLink}
            color="primary"
            to={`/customers/${record?.id}`}
            style={{ textDecoration: 'none' }}
        >
            {record?.first_name} {record?.last_name}
        </Typography>
        <Typography>Phone: {record?.username}</Typography>
        <Typography>DOB: {record?.birthday}</Typography>
    </Box>
);

// const CustomerAddress = ({ record }: { record?: Customer }) => (
//     <Box>
//         <Typography>
//             {record?.first_name} {record?.last_name}
//         </Typography>
//         <Typography>{record?.address}</Typography>
//         <Typography>
//             {record?.city}, {record?.stateAbbr} {record?.zipcode}
//         </Typography>
//     </Box>
// );

const useEditStyles = makeStyles({
    root: { alignItems: 'flex-start' },
});

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const OrderForm = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();

    const [loading, setLoading] = useState(false);

    // @ts-ignore
    const handleDownload = (id, test_id, phone) => {
        setLoading(true);
        const url =
            process.env.REACT_APP_API_SERVER! + '/commands/download-file';
        // @ts-ignore
        const auth = localStorage.getItem('auth');
        const options = {
            method: 'POST',
            mode: 'cors',
            // @ts-ignore
            body: JSON.stringify({
                id: id,
                test_id: test_id,
            }),
            headers: {
                'Content-Type': 'application/json',
                // @ts-ignore
                Authorization: 'Bearer ' + JSON.parse(auth).access_token,
            },
        };
        // @ts-ignore
        fetch(url, options)
            .then(res => res.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = phone + '-' + test_id + '.zip';
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove(); //afterwards we remove the element again
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                //Notify here
            });
    };

    return (
        <FormWithRedirect
            {...props}
            render={(formProps: any) => (
                <Box maxWidth="50em">
                    <Card>
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={8}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.commands.section.order'
                                        )}
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Labeled
                                                source="id"
                                                resource="commands"
                                            >
                                                <TextField
                                                    source="id"
                                                    resource="commands"
                                                    record={formProps.record}
                                                />
                                            </Labeled>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Labeled
                                                source="service_name"
                                                resource="commands"
                                            >
                                                <TextField
                                                    source="service_name"
                                                    resource="commands"
                                                    record={formProps.record}
                                                />
                                            </Labeled>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Labeled
                                                source="date"
                                                resource="commands"
                                            >
                                                <DateField
                                                    source="date"
                                                    resource="commands"
                                                    record={formProps.record}
                                                    showTime
                                                />
                                            </Labeled>
                                        </Grid>
                                        {permissions !=
                                            'medex_representative' && (
                                            <Grid item xs={12} sm={12} md={6}>
                                                <Labeled
                                                    source="status"
                                                    resource="commands"
                                                >
                                                    <TextField
                                                        source="status"
                                                        resource="commands"
                                                        record={
                                                            formProps.record
                                                        }
                                                    />
                                                </Labeled>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid container>
                                        {permissions ==
                                            'medex_representative' && (
                                            <Grid item xs={12} sm={12} md={6}>
                                                <SelectInput
                                                    resource="commands"
                                                    source="status"
                                                    choices={[
                                                        {
                                                            id: 'delivered',
                                                            name: 'delivered',
                                                        },
                                                        {
                                                            id: 'ordered',
                                                            name: 'ordered',
                                                        },
                                                        {
                                                            id: 'cancelled',
                                                            name: 'cancelled',
                                                        },
                                                        {
                                                            id: 'unknown',
                                                            name: 'unknown',
                                                            disabled: true,
                                                        },
                                                    ]}
                                                />
                                            </Grid>
                                        )}
                                        {/* <Grid item xs={12} sm={12} md={6}>
                                            <Box mt={2}>
                                                <BooleanInput
                                                    row={true}
                                                    resource="commands"
                                                    source="returned"
                                                />
                                            </Box>
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.commands.section.customer'
                                        )}
                                    </Typography>
                                    <ReferenceField
                                        source="customer_id"
                                        resource="commands"
                                        reference="customers"
                                        basePath="/customers"
                                        record={formProps.record}
                                        link={false}
                                    >
                                        <CustomerDetails />
                                    </ReferenceField>
                                    <Spacer />

                                    {/* <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.commands.section.shipping_address'
                                        )}
                                    </Typography> */}
                                    {/* <ReferenceField
                                        source="customer_id"
                                        resource="commands"
                                        reference="customers"
                                        basePath="/customers"
                                        record={formProps.record}
                                        link={false}
                                    >
                                        <CustomerAddress />
                                    </ReferenceField> */}
                                </Grid>
                            </Grid>
                            <Spacer />
                            {permissions == 'medex_representative' && (
                                <RadioButtonGroupInput
                                    source="service_type"
                                    choices={[
                                        { id: 'personal', name: 'Personal' },
                                        { id: 'corporate', name: 'Corporate' },
                                    ]}
                                />
                            )}
                            <TextInput
                                type="text"
                                source="notes"
                                fullWidth
                                multiline
                            />
                            {permissions == 'medex_representative' && (
                                <FileInput
                                    multiple
                                    source="attachments"
                                    accept="application/pdf"
                                >
                                    {/* <FileField source="src" title="title" /> */}
                                    <TextField source="src" title="title" />
                                </FileInput>
                            )}

                            {/* <Typography variant="h6" gutterBottom>
                                {translate('resources.commands.section.items')}
                            </Typography>
                            <Box>
                                <Basket record={formProps.record} />
                            </Box>
                            <Spacer /> */}

                            {/* <Typography variant="h6" gutterBottom>
                                {translate('resources.commands.section.total')}
                            </Typography> */}
                            {/* <Box>
                                <Totals record={formProps.record} />
                            </Box> */}
                        </CardContent>
                        <Toolbar
                            record={formProps.record}
                            basePath={formProps.basePath}
                            undoable={true}
                            invalid={formProps.invalid}
                            handleSubmit={formProps.handleSubmit}
                            saving={formProps.saving}
                            resource="commands"
                        />
                    </Card>
                </Box>
            )}
        />
    );
};
const OrderEdit: FC<EditProps> = props => {
    const classes = useEditStyles();
    return (
        <Edit
            title={<OrderTitle />}
            classes={classes}
            {...props}
            component="div"
        >
            <OrderForm />
        </Edit>
    );
};

export default OrderEdit;
